<template>
  <div>
    <!-- <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Inicio</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->
    <!-- Card stats -->
    <div class="header pb-6 d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-warning opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row g-0">
          <div class="col">
            <h1 class="display-2 text-white">
              Bienvenido al Club de Facturas.
            </h1>
            <h3 class="text-white display-4 mt-0 mb-3">
              Entre más facturas ingreses más oportunidades de ganar tendrás
            </h3>
          </div>
        </div>
      </div>
    </div>

    <!--cards-->
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <a @click="navigateToBills()" type="button" class="btn btn-neutral mb-3 btn-block">
              Agregar factura
            </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <a style="cursor: pointer;" @click="navigate('Historial de facturas')">
            <stats-card title="Historial" type="gradient-red" sub-title="Facturas" icon="fas fa-file-invoice" >              
              <template slot="footer">
                <span>En este espacio puedes verificar las facturas registradas a cada promoción.</span>
                <p class="text-white"> </p>
              </template>
            </stats-card>
          </a>
        </div>
        <div class="col-xl-6 col-md-12">
          <stats-card title="Información" type="gradient-green" sub-title="Tiempo para subir facturas" icon="fas fa-stopwatch">
            <template slot="footer">
                <span>Confirma con tu representante o promotor las fechas de cada promoción.</span>
                <p class="text-white"> </p>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 order-xl-12 ">
          <card >
            <!-- Card header -->
            <h3 slot="header"  class="mb-0 text-primary">Instrucciones</h3>

            <!-- Card body -->
            <div class="row">
              <div class="col-lg-8">
                <p class="mb-5">
                  <ul>
                    <li>
                      Registra cada factura que incluya la venta del producto participante.
                    </li>
                    <li>
                      Confirma con tu representante o promotor los productos participantes.
                    </li>
                    <li>
                      Entre más facturas ingreses más oportunidades de ganar tendrás.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
// Components

/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */


export default {
  components: {
   /*  RouteBreadCrumb, */
  },
  data() {
    return {
      routing: true,
      hours:0,
      days: 0,
      minutes: 0,
      seconds:0,
      datend: '12/1/2022 0:01 AM',
      noactive: true
    };
  },
  methods: {
    navigate(name) {
      this.$router.push({
        name
      });
    },
    navigateToBills() {
      const path = `/bills/add`;
      if (this.$route.path !== path && this.routing) {
        this.routing = false;
        this.$router.push(path);
      }
    },
    updateCounter(){
      let now = new Date();
      let end = new Date(this.datend);
      let distance = end - now;
      if(distance > 0){
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.noactive = false;
      }else{
        this.hours = 0;
        this.minutes = 0;
        this.days = 0;
        this.seconds = 0;
        this.noactive = true;
      }
    },
  },
   mounted() {
    //this.initBigChart(0);
    setInterval(this.updateCounter,1000)
  }
};
</script>
<style>
  .bg-warning{
    background: #000080!important;
    background: linear-gradient(70deg, #000080 0%, #000080 100%) !important;
  }
</style>
