<template>
  <div>
    <base-header class="pb-6 bg-warning">
     <!--  <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Inicio</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div> -->
      <!-- Card stats -->
      <div class="row pt-4">
       <!--  <div class="col-xl-6 col-md-12">
          <a style="cursor: pointer;" @click="navigate('Listado roles')">
            <stats-card title="Gestión" type="gradient-orange" sub-title="Roles" icon="fas fa-user-tag">

              <template slot="footer">
                <span>Gestiona los roles de la plataforma.</span>
              </template>
            </stats-card>
          </a>
        </div> -->
        <div class="col-xl-6 col-md-12">
          <a style="cursor: pointer;" @click="navigate('Listado usuarios')">
            <stats-card title="Gestión" type="gradient-blue" sub-title="Usuarios inscritos" icon="fas fa-users">

              <template slot="footer">
                <span>Usuarios inscritos en la plataforma y sus facturas subidas.</span>
              </template>
            </stats-card>
          </a>
        </div>

        <div class="col-xl-6 col-md-12">
          <a style="cursor: pointer;" @click="navigate('Reporte de Facturas')">
            <stats-card title="Gestión" type="gradient-red" sub-title="Facturas" icon="fas fa-file-invoice">

              <template slot="footer">
                <span>Gestiona las facturas subidas a la plataforma.</span>
              </template>
            </stats-card>
          </a>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-xl-6 col-md-12">
          <a style="cursor: pointer;" @click="navigate('Tombola')">
            <stats-card title="Tómbola del Éxito" type="gradient-green" sub-title="Tómbola" icon="fab fa-hackerrank">

              <template slot="footer">
                <span>Generar 10 ganadores mensuales por país.</span>
              </template>
            </stats-card>
          </a>
        </div>
      </div>-->
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-12">
          <card>
            <!-- Card header -->
            <h3 slot="header" class="mb-0" style="color: #17a4e4">Información</h3>

            <!-- Card body -->
            <div class="row">
              <div class="col-lg-8">
                <p class="mb-5">
                  Bienvenido administrador.
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  // Components
/*   import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'; */
  import StatsCard from '@/components/Cards/StatsCard';

  export default {
    components: {
      /* RouteBreadCrumb, */
      StatsCard
    },
    data() {
      return {

      };
    },
    methods: {
      navigate(name) {
        this.$router.push({
          name
        });
      }
    }
  };

</script>
<style>
  .bg-warning{
    background: #000080!important;
    background: linear-gradient(90deg, #000080 0%, #000080 100%) !important;
  }
</style>
